import {Injectable} from '@angular/core';
import {HttpWrapperService} from '../../core/services/http-wrapper.service';
import {AppConstant} from '../../core/services/constant';

@Injectable({
  providedIn: 'root'
})
export class CallService {
  private callHistory = AppConstant.apiUrl + AppConstant.callHistory;
  private callHistoryDetails = AppConstant.apiUrl + AppConstant.callHistoryDetails;
  private callChatRefund = AppConstant.apiUrl + AppConstant.callChatRefund;
  private saveNotes = AppConstant.apiUrl + AppConstant.saveNotes;
  private getNotes = AppConstant.apiUrl + AppConstant.getNotes;

  private checkBlockStatusApi = AppConstant.supportApiUrl + AppConstant.checkBlockStatus;
  private createBlockTicketApi = AppConstant.supportApiUrl + AppConstant.createBlockTicket;
  private sendBlockMessageApi = AppConstant.supportApiUrl + AppConstant.sendBlockMessage;
  private checkConsultantEarningApi = AppConstant.apiUrl + AppConstant.checkConsultantEarningApi;
  private blockUserApi = AppConstant.apiUrl + AppConstant.blockUserApi;

  constructor(private http: HttpWrapperService) { }

  getCallHistory = (params): any => {
    return this.http.get(this.callHistory, params, true);
  }

  getCallHistoryDetails = (params): any => {
    return this.http.get(this.callHistoryDetails, params, true);
  }

  getNote = (params) => {
    return this.http.get(this.getNotes, params, true);
  }

  saveNote = (params, body) => {
    return this.http.postHeaderParamsAndBody(this.saveNotes, params, body, true);
  }

  refundCallChatAmount = (params): any => {
    return this.http.postHeaderParams(this.callChatRefund, params, true);
  }

  checkUserBlockStatus = (params): any => {
    return this.http.postHeaderParams(this.checkBlockStatusApi, params, true);
  }

  checkConsultantEarning = (params): any => {
    return this.http.get(this.checkConsultantEarningApi, params, true);
  }

  blockUser = (params): any => {
    return this.http.postParams(this.blockUserApi, params, true);
  }

  createBlockTicket = (params): any => {
    return this.http.postJson(this.createBlockTicketApi, params, true);
  }

  sendBlockMessage = (params): any => {
    return this.http.postJson(this.sendBlockMessageApi, params, true);
  }
}
