import { Injectable } from '@angular/core';
import {HttpWrapperService} from '../../core/services/http-wrapper.service';
import {AppConstant} from '../../core/services/constant';

@Injectable({
  providedIn: 'root'
})
export class AstroService {
  private astroMallHistory = AppConstant.apiUrl + AppConstant.astroMallHistory;
  private saveNotes = AppConstant.apiUrl + AppConstant.saveNotes;
  private getNotes = AppConstant.apiUrl + AppConstant.getNotes;
  private closeOrder = AppConstant.astroMallApi + AppConstant.closeAstromallOrder;
  private getGroupChatHistory = AppConstant.apiUrl + AppConstant.getGroupChatHistory;
  private groupSendMessage = AppConstant.apiUrl + AppConstant.groupSendMessage;
  private groupGetLastMessage = AppConstant.apiUrl + AppConstant.groupGetLastMessage;
  private groupChatDelete = AppConstant.apiUrl + AppConstant.groupChatDelete;
  private antiFraudFlagsAPI = AppConstant.chatApi + AppConstant.antiFraudFlagsAPI;
  private fraudFlagsAPI = AppConstant.chatApi + AppConstant.fraudFlagsAPI;
  constructor(private http: HttpWrapperService) { }

  getAstroMallHistory = (params): any => {
    return this.http.get(this.astroMallHistory, params, true);
  }

  getNote = (params) => {
    return this.http.get(this.getNotes, params, true);
  }

  saveNote = (params, body) => {
    return this.http.postHeaderParamsAndBody(this.saveNotes, params, body, true);
  }

  closeAstromallOrder = (params) => {
    return this.http.postHeaderParams(this.closeOrder, params, true);
  }

 /* getOrderDetails = (params) => {
    return this.http.get(this.getGroupChatHistory, params, true);
  }*/

  getChatHistory = (params) => {
    return this.http.get(this.getGroupChatHistory, params, true);
  }

  getLastMessage = (params) => {
    return this.http.get(this.groupGetLastMessage, params, true);
  }

  sendMessage = (body) => {
    return this.http.postJson(this.groupSendMessage, body, true);
  }

  deleteChatMessage = (params) => {
    return this.http.get(this.groupChatDelete, params, true);
  }

  getFraudFlags = (params) => {
    return this.http.get(this.fraudFlagsAPI, params, true);
  }

  getAntiFraudFlags = (params) => {
    return this.http.get(this.antiFraudFlagsAPI, params, true);
  }
}
